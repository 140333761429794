
import printJS from 'print-js';


///字符串转日期
export function StrToDate(datestr) {
  return new Date(datestr);
}

//_this.Query.beginDate=dateFormat('YYYY-mm-dd',_this.start_end[0]);
///日期格式化
export function dateFormat(fmt, date) {
  let ret;
  const opt = {
      "Y+": date.getFullYear().toString(),        // 年
      "m+": (date.getMonth() + 1).toString(),     // 月
      "d+": date.getDate().toString(),            // 日
      "H+": date.getHours().toString(),           // 时
      "M+": date.getMinutes().toString(),         // 分
      "S+": date.getSeconds().toString()          // 秒
      // 有其他格式化字符需求可以继续添加，必须转化成字符串
  };
  for (let k in opt) {
      ret = new RegExp("(" + k + ")").exec(fmt);
      if (ret) {
          fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
      };
  };
  return fmt;
}

///字典排序的方法
export function objKeySort(arys) {
  //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
  var newkey = Object.keys(arys).sort();　　
  //console.log('newkey='+newkey);
  var newObj = {}; //创建一个新的对象，用于存放排好序的键值对
  for(var i = 0; i < newkey.length; i++) {
      //遍历newkey数组
      newObj[newkey[i]] = arys[newkey[i]];
      //向新创建的对象中按照排好的顺序依次增加键值对

  }
  return newObj; //返回排好序的新对象
};

export function PostobjKeySort(arys) {
  //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
  var newkey = Object.keys(arys).sort();
　
  //console.log('newkey='+newkey);
  var newObj = {}; //创建一个新的对象，用于存放排好序的键值对
  for(var i = 0; i < newkey.length; i++) {
      //遍历newkey数组
      newObj[newkey[i]] = arys[newkey[i]];
      //向新创建的对象中按照排好的顺序依次增加键值对

  }
   let par={};
   let md5s='';
   for (let i in newObj)
   {
    if(newObj[i]===0)
    {
      md5s+=i+"="+newObj[i]+"&";
      par[i]=newObj[i];
    }

      else
      {if (newObj[i]!='')
     {
        md5s+=i+"="+newObj[i]+"&";
        par[i]=newObj[i];
      }
    }

   }
      if(md5s.length>1){
      md5s=md5s.slice(0,-1)
      }
  let obj={md5s:md5s,par:par}    


  return obj; //返回排好序的新对象
};

export function printJson(tableData){
    let data=[];
    for (let i=0;i<  tableData.length ;i++){
      let dict={};
      let x=1;
      for (var key in tableData[0])
      {
        dict["field"+x]=tableData[i][key];
           x+=1;
      }
         data.push(dict)
    }
    console.log(data)
    let p_col=[]
    let s=1;
   for (var p1 in tableData[0])
   {if(tableData[0].hasOwnProperty(p1))
   {p_col.push({field:'field'+s,displayName:p1,clumnSize:1})
   s +=1;}
   }

    printJS({
      printable:data,
      properties:p_col,
      type:'json',
      header:'标题',
      gridStyle:'border:2px solid #3971A5;',
      gridHeaderStyle:'color:red;border:2px solid #3971A5;'

    })

  }