
export default {
    bindOpendId(state,v){
        state.bindOpendId=v
    },
    setUserName(state,v){
        state.userName=v
    },
    newLogin(state,v){
     state.unionid=v.unionid
    state.ip=v.ip
    state.currentMart=v.martName
    state.currentMartId=v.martId
    state.superCode=v.superCode
    state.superName=v.superName
    state.userName=v.userName
    },
    control_Login(state,v){
        state.unionid=v.unionid
        state.ip=v.ip
        state.currentMart=v.martName
        state.currentMartId=v.martId
        state.userName=v.userName
        
    },
    setgyllist(state,v){
        state.gyllist=v;
    },

    login(state,v) {
        state.userinfo = v.code
        state.userName=v.name
        state.userid=v.user_id
    },
    setmemberinfo(state,v){
        state.userstatus=v.userstatus
        state.userlevel=v.userlevel

    },
    changebodywidth(state,v){
      state.bodyWidth=v
    },
    removeTabs(state,v){
       let i= state.editableTabs.find(item=>{
            return  item.name == v
         })
         console.log("需要移除",v)

         state.editableTabs.splice(i,1)

    },
    addtabs(state,v){

        let item = state.editableTabs.find(item=>{
           return  item.name == v.index
        })
        //使用find找数组内的相同值,用return返回,如果没有返回undefined
        if(item!=undefined){
            if(v.title=="报表明细"){
                state.editableTabs.push({
                    title: v.title,
                    name: v.index,
                    content: v.content,
                    parameter:v.parameter
                });
                state.editableTabsValue=v.index
            }
            else{
                state.editableTabsValue=v.index
            }
        }
        else{
            state.editableTabs.push({
                title: v.title,
                name: v.index,
                content: v.content,
                parameter:v.parameter
            });
            console.log("我添加了",v.title)
            if(v.title==="线上采购订单编辑")
            {

                state.orderSaveState=true;
            }
            state.editableTabsValue=v.index
        }

    },
    updateeditableTabsValue(state,v){
        state.editableTabsValue=v.v
    },
   upate_sidentify(state,v) {
       state.sidentify = v
   },
   setOpenid(state,v){
    state.unionid=v
   },
   getlocalIp(state,v){

    state.ip=v
   },

   changeSavestate(state)
   {
    state.orderSaveState=false;
   },

    remtabs(state,v){

        for(var i = 0; i < state.editableTabs.length; i++){

            if(state.editableTabs[i].name === v.targetName){

               console.log(state.editableTabs[i].title,state.orderSaveState);

               if(state.editableTabs[i].title==="线上采购订单编辑" && state.orderSaveState===true )
               var r=confirm("是否回去保存单据？")
               if(r==true){
                   return;
               }
                state.editableTabs.splice(i,1);
                state.editableTabsValue = '1-1'


            }
        }

    }
}
