import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)



const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes:[{
    path:"/login",
    name:"login",
    component:() => import("../pages/login.vue")
  },
   {
      path:"/customer",
      name:"customer",
      component:() => import("../pages/Customer_use.vue")
    },
    
    {
      path:"/manual_bind",
      name:"manual_bind",
      component:() => import("../pages/manual_bind.vue")

    },
    {
      path:"/select_mart",
      name:"select_mart",
      component:() => import("../pages/select_mart.vue")

    },
    {
      path:"/main",
      name:"main",
      component:() => import("../pages/main.vue")

    },
    {
      path:"/control_login",
      name:"control_login",
      component:() => import("../pages/control_login.vue")

    },
   
    {
      path:"/bindopenid",
      name:"bindopenid",
      component:() => import("../pages/bindOpenID.vue")

    },
    {
      path:"/main_control",
      name:"main_control",
      component:() => import("../pages/main_control.vue")

    },
    {
      path:"/weixin_reg",
      name:"weixin_reg",
      component:() => import("../pages/weixin_reg.vue")

    },
  


    

    ]
})

export default router
