<template>
<div class="body_form">
 <el-row>

      <div class="buttons-div">
      <el-container>
      <el-button size="mini" type="warning" icon="el-icon-download" plain @click="exportdata">导出</el-button>  
      <el-button size="mini" type="success"  plain icon="el-icon-printer" @click="printEvent">打印</el-button>
      </el-container>
      </div>
    </el-row>
    <el-row>
        <el-col v-show="customer.show" class="left-span" :span="2"> <p >供应商:</p></el-col>
        <el-col v-show="customer.show" :span="4" class="left-span-content"> <p >{{customer.val}}</p></el-col>
        <el-col v-show="sheetno.show"  class="left-span" :span="2"> <p >单号: </p></el-col>
        <el-col v-show="sheetno.show" :span="4" class="left-span-content"> <p > {{sheetno.val}}</p></el-col>
         <el-col v-show="仓库编码.show" class="left-span" :span="2"> <p  >仓库:</p></el-col>
         <el-col v-show="仓库编码.show" class="left-span-content" :span="4" > <p  >{{仓库编码.val}} </p></el-col>
        <el-col v-show="仓库.show" class="left-span" :span="2"> <p  >仓库:  </p></el-col>
        <el-col v-show="仓库.show" class="left-span-content" :span="4"> <p  >{{仓库.val}} </p></el-col>
          <el-col v-show="审核标识.show" class="left-span" :span="2"> <p >审核标识:  </p></el-col>
          <el-col v-show="审核标识.show" :span="4" class="left-span-content"> <p >{{审核标识.val}}</p></el-col>
          <el-col v-show="审核日期.show" class="left-span" :span="2"> <p >审核日期: </p></el-col>
          <el-col v-show="审核日期.show" :span="4" class="left-span-content"> <p > {{审核日期.val}}</p></el-col>
    </el-row>

    <el-row>
        <el-col v-if="送货状态.show" class="left-span" :span="2"> <p  >送货状态: </p></el-col>
        <el-col v-show="送货状态.show" :span="4" class="left-span-content"> <p > {{送货状态.val}}</p></el-col>
        <el-col v-if="结算日期.show" class="left-span" :span="2"> <p  >结算日期: </p></el-col>
        <el-col v-show="结算日期.show" :span="4" class="left-span-content"> <p > {{结算日期.val}}</p></el-col>
        <el-col v-show="交货日期.show" class="left-span" :span="2"> <p  >交货日期:  </p></el-col>
        <el-col v-show="交货日期.show" :span="4" class="left-span-content"> <p >{{交货日期.val}}</p></el-col>
         <el-col v-show="单据金额.show" class="left-span" :span="2"> <p >单据金额: </p></el-col>
         <el-col v-show="单据金额.show" :span="4" class="left-span-content"> <p >  {{单据金额.val}}</p></el-col>
          <el-col v-show="收支方式.show" class="left-span" :span="2"> <p >收支方式: </p></el-col>
         <el-col v-show="收支方式.show" :span="4" class="left-span-content"> <p >  {{收支方式.val}}</p></el-col>
         <el-col v-show="日期.show" class="left-span" :span="2"> <p >日期: </p></el-col>
         <el-col v-show="日期.show" :span="4" class="left-span-content"> <p >  {{日期.val}} </p></el-col>
        <el-col v-show="订金金额.show" class="left-span" :span="2"> <p >订金金额:  </p></el-col>      
        <el-col v-show="订金金额.show" :span="4" class="left-span-content"> <p > {{订金金额.val}}</p></el-col>
        <el-col v-show="实际付款.show" class="left-span" :span="2"> <p >实际付款:  </p></el-col>
        <el-col v-show="实际付款.show" :span="4" class="left-span-content"> <p > {{实际付款.val}}</p></el-col>
    </el-row>

    <el-row>
        <el-col v-show="描述.show"  :span="24">  <p >描述: {{描述.val}} </p>
        </el-col>
    </el-row>
    <el-row>
        <el-col v-show="备注.show"  :span="24">  <p >备注: {{备注.val}} </p>
        </el-col>

    </el-row>
  <vxe-toolbar ref="xToolbar" custom ></vxe-toolbar>
  
              <vxe-table
                size="mini"
                 ref="xTable"
                 :data.sync="tableData"
                 height="350"
                show-footer
                :footer-method="footerMethod"
                :footer-cell-class-name="footerCellClassName"
                border>
          <vxe-table-column type="index" width="60"></vxe-table-column>
          <vxe-table-column v-for="(item,key,index) in tableData[0]" :key="index" :field="key"  :title="key"  > </vxe-table-column>

            </vxe-table>

</div>

</template>
<script >
import { printJson } from '@/components/common/js/printHelp.js'
import  md5  from '../components/common/js/allSchool.js'
import {objKeySort} from '@/components/common/js/printHelp.js'
import { exportAllDataEvent4 } from '@/components/common/js/util.js'

const printStyle = `
        .title {
          text-align: center;
        }
        .my-list-row {
          display: inline-block;
          width: 100%;
        }
        .my-list-col {
          float: left;
          width: 33.33%;
          height: 28px;
          line-height: 28px;
        }
        .my-top,
        .my-bottom {
          font-size: 12px;
        }
        .my-top {
          margin-bottom: 5px;
        }
        .my-bottom {
          margin-top: 30px;
          text-align: right;
        }
        `;
export default{
     components: {
      exportAllDataEvent4,
            printJson,
            objKeySort
        },
    props:['title','parameter'],
    data(){
        return{
            tableHeight: 0,
            tableMaxHeight:0,
            Query:{
                supcustNo:this.$store.state.superCode,
                sheetNo:'',
                unionid:this.$store.state.unionid,
                ip:this.$store.state.ip,
                scm_code:this.$store.state.currentMartId
            },
            sheetno:{show:false,val:''},
            customer:{show:false,val:''},
            交货日期:{show:false,val:''},
            结算日期:{show:false,val:''},
            收支方式:{show:false,val:''},
            实际付款:{show:false,val:''},
            审核标识:{show:false,val:''},
            审核日期:{show:false,val:''},
            仓库:{show:false,val:''},
            仓库编码:{show:false,val:''},
            订金金额:{show:false,val:''},
            单据金额:{show:false,val:''},
            日期:{show:false,val:''},
            描述:{show:false,val:''},
            备注:{show:false,val:''},
            送货状态:{show:false,val:''},
            tableData:[  ],

            datatable1:[{name:"zlf",age:10},
            {name:"thsen",age:20}
            ]
        }
    },
    mounted(){
            this.init();
            this.select_init();
            this.$nextTick(() => {
            console.log(window.innerHeight)
            this.tableHeight = window.innerHeight - 100;
            this.tableMaxHeight=window.innerHeight - 50;

        // 后面的50：根据需求空出的高度，自行调
        })

          },
    methods:{

      exportdata(){
              console.log("测试",this.tableData)
              if(this.tableData.length>0){
                let clo_key=[];
                for (var p1 in this.tableData[0])
                {if(this.tableData[0].hasOwnProperty(p1))
                clo_key.push({title:p1,key:p1})
                }
                  exportAllDataEvent4(this.title,this.$refs.xTable,this.tableData)
                  //export2Excel(clo_key,this.tableData,this.title)
              }
              else{
                confirm("没有数据可以导出");
              }
              },

        footerCellClassName ({ rowIndex, column, columnIndex }) {
              if (columnIndex === 0) {
                if (rowIndex === 0) {
                  return 'col-blue'
                } else {
                  return 'col-red'
                }
              }
            },
        ///计算合计
        footerMethod ({ columns, data }) {
              return [
                columns.map((column, columnIndex) => {
                  if (columnIndex === 0) {
                    return '合计'
                  }
                  if (['数量', '箱数','金额','扣补金额'].includes(column.property)) {
                    return this.$utils.sum(data, column.property)
                  }
                  return ''
                })
              ]
            },
            ////打印
printEvent () {
let topHtml = `
        <h1 class="title">`+this.title+`</h1>
        <div class="my-top">
          <div class="my-list-row">
            <div class="my-list-col">供应商：`+this.customer.val+`</div>
            <div class="my-list-col">订单单号：`+this.sheetno.val+`</div>
            <div class="my-list-col">订单金额：`+this.订金金额.val+`</div>
          </div>
          <div class="my-list-row">
            <div class="my-list-col">日期：`+this.日期.val+`</div>
            <div class="my-list-col">门店：`+this.仓库.val+`</div>  
            <div class="my-list-col">备注：`+this.备注.val+`</div>
            
          </div>
        </div>
        `;
             this.$refs.xTable.print({
                sheetName: '',
                style: printStyle,
                mode:"all",             
                beforePrintMethod: ({content}) => {
                    // 拦截打印之前，返回自定义的 html 内容
                //    return topHtml + content + bottomHtml
                return topHtml + content 
                }
            })

            

      },
        init(){
               this.Query.sheetNo=this.parameter
               //this.Query.supcustNo =this.$store.state.userinfo.userinfo


               switch (this.title){
                   case '线上采购订单明细':{
                       this.url='/SCM/GetOnLinePODetail?sign=';
                       break;
                   }
                   case '采购订单明细':{
                       this.url='/SCM/GetPODetail?sign=';
                       break;
                   }
                   case '采购收货单明细':{
                        this.url='/SCM/GetPIDetail?sign=';
                        break;
                   }
                   case '采购退货单明细':{
                        this.url='/SCM/GetRODetail?sign=';
                        break;
                   }


                   case '费用单明细':{
                        this.url='/SCM/GetFGDetail?sign=';
                        break;
                   }
                   case '扣补单明细':{
                        this.url='/SCM/GetKBDetail?sign=';
                        break;
                   }
                    case '预付单明细':{
                        this.url='/SCM/GetSADetail?sign=';
                        break;
                   }
                    case '联营账款明细':{
                        this.url='/SCM/GetLYDetail?sign=';
                        break;
                   }
                   case '购销返利明细':{
                        this.url='/SCM/GetCPDetail?sign=';
                        break;
                   }
                    case '扣率代销账款明细':{
                        this.url='/SCM/GetKLDetail?sign=';
                        break;
                   }


               }

        },
        select_init(){
            let _this=this;

            let newkey = objKeySort(this.Query)
            let par={};
            let md5s='';
             for (let i in newkey)
            {

              if (newkey[i]!='')
              {
                md5s+=i+"="+newkey[i]+"&";
                par[i]=newkey[i];
              }

            }
            if(md5s.length>1){
              md5s=md5s.slice(0,-1)
            }
            let sign= md5.M5(md5s)
            _this.reqM1Service(_this.url+sign,par,'post')
           // _this.$axios.post(_this.url+sign,par)
            .then((res=>{
                console.log("当前的标题",this.title,res.data)
                if(res.data.code==200){
                    let headers=res.data.data;
                    ///接收了detail'
                    _this.tableData=res.data.data.items
                   // console.log("当前的标题",this.title)
                    switch (this.title){
                   case '线上采购订单明细':{
                       _this.sheetno.val=headers.单号
                       _this.sheetno.show=true
                       _this.customer.val=headers.供应商
                       _this.customer.show=true
                       _this.交货日期.val=headers.交货期限.slice(0,10)
                       _this.交货日期.show=true
                       _this.仓库.val=headers.仓库名称
                       _this.仓库.show=true
                       _this.备注.val=headers.备注
                       _this.备注.show=true
                       _this.审核标识.val=headers.审核标识
                        _this.审核标识
                       _this.描述.val=headers.描述
                       _this.描述.show=true
                       _this.日期.val=headers.日期.slice(0,10)
                       _this.日期.show=true
                       _this.订金金额.val=headers.订金金额
                        _this.订金金额.show=true
                       break;
                   }
                   case '采购订单明细':{
                       _this.sheetno.val=headers.单号
                       _this.sheetno.show=true
                       _this.customer.val=headers.供应商
                       _this.customer.show=true
                       _this.交货日期.val=headers.交货期限
                        _this.交货日期.show=true
                        _this.仓库.val=headers.仓库名称
                        _this.仓库.show=true
                        _this.仓库编码.val=headers.仓库编码
                         _this.仓库编码.show=true
                        _this.备注.val=headers.备注
                         _this.备注.show=true
                        _this.审核标识.val=headers.审核状态
                        _this.审核标识.show=true
                        _this.审核日期.val=headers.审核日期.slice(0,10)
                        _this.审核日期.show=true
                        _this.描述.val=headers.描述
                        _this.描述.show=true
                        _this.日期.val=headers.日期.slice(0,10)
                        _this.日期.show=true
                        _this.订金金额.val=headers.订金金额
                         _this.订金金额.show=true
                        _this.送货状态.val=headers.送货状态
                        _this.送货状态.show=true

                       break;
                   }
                            case '采购收货单明细':{

                       _this.sheetno.val=headers.单号
                       _this.sheetno.show=true
                       _this.customer.val=headers.供应商
                       _this.customer.show=true
                       _this.交货日期.val=headers.交货期限
                        _this.交货日期.show=true
                        _this.仓库.val=headers.仓库名称
                        _this.仓库.show=true
                        _this.仓库编码.val=headers.仓库编码
                         _this.仓库编码.show=false
                        _this.备注.val=headers.备注
                         _this.备注.show=true
                        _this.审核标识.val=headers.审核状态
                        _this.审核标识.show=true
                        //_this.审核日期.val=headers.审核日期.slice(0,10)
                        _this.审核日期.show=false
                        _this.描述.val=headers.描述
                        _this.描述.show=false
                        _this.日期.val=headers.日期
                        _this.日期.show=true
                        //_this.订金金额.val=headers.订金金额
                         _this.订金金额.show=false
                        _this.单据金额.val=headers.单据金额
                         _this.单据金额.show=true
                        //_this.送货状态.val=headers.送货状态
                        _this.送货状态.show=false

                       break;
                   }
                    case '采购退货单明细':{

                       _this.sheetno.val=headers.单号
                       _this.sheetno.show=true
                       _this.customer.val=headers.供应商
                       _this.customer.show=true
                       _this.交货日期.val=headers.交货期限
                        _this.交货日期.show=false
                        _this.仓库.val=headers.仓库名称
                        _this.仓库.show=true
                        _this.仓库编码.val=headers.仓库编码
                         _this.仓库编码.show=false
                        _this.备注.val=headers.备注
                         _this.备注.show=true
                        _this.审核标识.val=headers.审核状态
                        _this.审核标识.show=true
                        //_this.审核日期.val=headers.审核日期.slice(0,10)
                        _this.审核日期.show=false
                        _this.描述.val=headers.描述
                        _this.描述.show=false
                        _this.日期.val=headers.日期
                        _this.日期.show=true
                        _this.订金金额.val=headers.订金金额
                         _this.订金金额.show=false
                        _this.单据金额.val=headers.订单金额
                         _this.单据金额.show=true
                        //_this.送货状态.val=headers.送货状态
                        _this.送货状态.show=false

                       break;
                   }
                    case '联营账款明细':{
                    _this.sheetno.val=headers.单号
                       _this.sheetno.show=true
                       _this.customer.val=headers.供应商
                       _this.customer.show=true
                       //_this.交货日期.val=headers.交货期限
                        _this.交货日期.show=false
                        _this.结算日期.val=headers.结算开始日期
                        _this.结算日期.show=true
                        //_this.收支方式.val=headers.收支方式
                        //_this.收支方式.show=true
                        _this.仓库.val=headers.机构
                        _this.仓库.show=true
                        //_this.仓库编码.val=headers.机构编码
                         _this.仓库编码.show=false
                        _this.备注.val=headers.备注
                         _this.备注.show=true
                        //_this.审核标识.val=headers.审核状态
                        _this.审核标识.show=false
                        //_this.审核日期.val=headers.审核日期.slice(0,10)
                        _this.审核日期.show=false
                        _this.描述.val=headers.分摊描述
                        _this.描述.show=true
                        _this.日期.val=headers.操作日期
                        _this.日期.show=true
                        _this.实际付款.val=headers.实际付款
                         _this.实际付款.show=true
                        //_this.订金金额.val=headers.订金金额
                         _this.订金金额.show=false
                        _this.单据金额.val=headers.价税合计
                         _this.单据金额.show=true
                        //_this.送货状态.val=headers.送货状态
                        _this.送货状态.show=false



                    break;
                  }
                  case '购销返利明细':{

                    break;
                  }
                  case '扣率代销账款明细':{
                     _this.sheetno.val=headers.单号
                       _this.sheetno.show=true
                       _this.customer.val=headers.供应商
                       _this.customer.show=true
                       //_this.交货日期.val=headers.交货期限
                        _this.交货日期.show=false
                        _this.结算日期.val=headers.结算开始日期
                        _this.结算日期.show=true
                        //_this.收支方式.val=headers.收支方式
                        //_this.收支方式.show=true
                        _this.仓库.val=headers.机构
                        _this.仓库.show=true
                        //_this.仓库编码.val=headers.机构编码
                         _this.仓库编码.show=false
                        _this.备注.val=headers.备注
                         _this.备注.show=true
                        //_this.审核标识.val=headers.审核状态
                        _this.审核标识.show=false
                        //_this.审核日期.val=headers.审核日期.slice(0,10)
                        _this.审核日期.show=false
                        _this.描述.val=headers.分摊描述
                        _this.描述.show=true
                        _this.日期.val=headers.操作日期
                        _this.日期.show=true
                        _this.实际付款.val=headers.实际付款
                         _this.实际付款.show=true
                        //_this.订金金额.val=headers.订金金额
                         _this.订金金额.show=false
                        _this.单据金额.val=headers.价税合计
                         _this.单据金额.show=true
                        //_this.送货状态.val=headers.送货状态
                        _this.送货状态.show=false
                    break;
                  }
                   case '费用单明细':{
                       _this.sheetno.val=headers.单号
                       _this.sheetno.show=true
                       _this.customer.val=headers.供应商编码+headers.供应商名称
                       _this.customer.show=true
                       _this.交货日期.val=headers.交货期限
                        _this.交货日期.show=false
                        _this.结算日期.val=headers.结算日期
                        _this.结算日期.show=true
                        _this.收支方式.val=headers.收支方式
                        _this.收支方式.show=true
                        _this.仓库.val=headers.机构名称
                        _this.仓库.show=true
                        _this.仓库编码.val=headers.机构编码
                         _this.仓库编码.show=true
                        _this.备注.val=headers.备注
                         _this.备注.show=true
                        _this.审核标识.val=headers.审核状态
                        _this.审核标识.show=false
                        //_this.审核日期.val=headers.审核日期.slice(0,10)
                        _this.审核日期.show=false
                        _this.描述.val=headers.描述
                        _this.描述.show=false
                        _this.日期.val=headers.制单日期
                        _this.日期.show=true
                        //_this.订金金额.val=headers.订金金额
                         _this.订金金额.show=false
                        _this.单据金额.val=headers.单据金额
                         _this.单据金额.show=false
                        //_this.送货状态.val=headers.送货状态
                        _this.送货状态.show=false
                        break;
                   }
                   case '扣补单明细':{
                       _this.sheetno.val=headers.单号
                       _this.sheetno.show=true
                       _this.customer.val=headers.供应商编码+headers.供应商名称
                       _this.customer.show=true
                       _this.交货日期.val=headers.交货期限
                        _this.交货日期.show=false
                        _this.结算日期.val=headers.付款日期
                        _this.结算日期.show=true
                        _this.收支方式.val=headers.扣补方式
                        _this.收支方式.show=true
                        _this.仓库.val=headers.机构名称
                        _this.仓库.show=true
                        _this.仓库编码.val=headers.机构编码
                         _this.仓库编码.show=true
                        _this.备注.val=headers.备注
                         _this.备注.show=true
                        _this.审核标识.val=headers.审核状态
                        _this.审核标识.show=false

                        _this.审核日期.show=false
                        _this.描述.val=headers.原因
                        _this.描述.show=true
                        _this.日期.val=headers.制单日期
                        _this.日期.show=true

                         _this.订金金额.show=false
                        _this.单据金额.val=headers.单据金额
                         _this.单据金额.show=false

                        _this.送货状态.show=false
                        break;
                   }
                   case '预付单明细':{
                       _this.sheetno.val=headers.单号
                       _this.sheetno.show=true
                       _this.customer.val=headers.供应商编码+headers.供应商名称
                       _this.customer.show=true
                       _this.交货日期.val=headers.交货期限
                        _this.交货日期.show=false
                        _this.结算日期.val=headers.付款日期
                        _this.结算日期.show=true
                        _this.收支方式.val=headers.扣补方式
                        _this.收支方式.show=true
                        _this.仓库.val=headers.机构名称
                        _this.仓库.show=true
                        _this.仓库编码.val=headers.机构编码
                         _this.仓库编码.show=true
                        _this.备注.val=headers.备注
                         _this.备注.show=true
                        _this.审核标识.val=headers.审核状态
                        _this.审核标识.show=false

                        _this.审核日期.show=false
                        _this.描述.val=headers.原因
                        _this.描述.show=true
                        _this.日期.val=headers.制单日期
                        _this.日期.show=true

                         _this.订金金额.show=false
                        _this.单据金额.val=headers.单据金额
                         _this.单据金额.show=false

                        _this.送货状态.show=false
                        break;
                   }
               }
                }
                }
              ))
        }
    }
}

</script>
<style>
.left-span{

      text-align:right;
      line-height: 26px;
      font-size: 14px;

  }
 .left-span-content{
        line-height: 26px;
        font-size: 14px;
        min-height: 25px;
        border-radius: 0px;
        border-bottom: 1px solid #999;
        }
.body_form{
    height :95%
}
.el-table__body-wrapper {

    height: 240px;
}
</style>

