<template>
<div class="body_form">

  <el-row class=".bg-purple-light">
<div class="buttons-div">
      <el-container>
     <el-button size="mini" type="warning" icon="el-icon-download" plain @click="exportdata">导出</el-button> 
      <el-button size="mini" type="success" plain icon="el-icon-printer" @click="printEvent">打印</el-button>
      
      </el-container>
</div>

    </el-row>
    <el-row>
      <el-col class="left-span" :span="3">
          <span >批注：</span>
      </el-col>
          <el-col :span="21">
          <span  ><el-input size="mini" v-model="sheet.描述" style="padding-right:10px; width:300px;" ></el-input></span>
          <el-button size="mini" type="success" plain @click="submit_sheet">同意</el-button>
          <el-button size="mini" type="warning" plain>不同意</el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col class="left-span" :span="3">
          <span >供应商：</span>
      </el-col>
      <el-col class="left-span-content" :span="4">
          <div >{{sheet.供应商编码+sheet.供应商名称}}</div>
      </el-col>
      <el-col class="left-span" :span="3">
          <span >单号：</span>
      </el-col>
      <el-col class="left-span-content" :span="4">
          <span >{{sheet.单号}}</span>
      </el-col>
      <el-col class="left-span" :span="3">
          <span >付款状态：</span>
      </el-col>
      <el-col class="left-span-content" :span="4">
          <span >{{sheet.审核状态+','+sheet.确认状态}}</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col class="left-span" :span="3">
          <span >机构：</span>
      </el-col>
      <el-col class="left-span-content" :span="4">
          <span >{{sheet.机构名称}}</span>
      </el-col>
      <el-col class="left-span" :span="3">
          <span  >操作员：</span>
      </el-col>
      <el-col class="left-span-content" :span="4">
          <div >{{sheet.操作员}}</div>
      </el-col>
      <el-col class="left-span" :span="3">
          <span >制单日期：</span>
      </el-col>
      <el-col class="left-span-content" :span="4">
          <span >{{sheet.制单日期}}</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col class="left-span" :span="3">
          <span >结算开始日期：</span>
      </el-col>
      <el-col class="left-span-content" :span="4">
          <span >{{sheet.结算开始日期}}</span>
      </el-col>
      <el-col class="left-span" :span="3">
          <span >结算结束日期：</span>
      </el-col>
      <el-col class="left-span-content" :span="4">
          <span >{{sheet.结算结束日期}}</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col class="left-span" :span="3">
          <span >结算方式：</span>
      </el-col>
      <el-col class="left-span-content" :span='4'>
          <span >{{sheet.结算方式}}</span>
      </el-col>
      <el-col class="left-span" :span="3">
          <span >结账天数/月结日期：</span>
      </el-col>
      <el-col class="left-span-content" :span="4">
          <span >{{sheet.结账天数}}</span>
      </el-col>
      <el-col class="left-span" :span="3">
          <span >审核日期：</span>
      </el-col>
      <el-col class="left-span-content" :span="4">
          <span >{{sheet.审核日期}}</span>
      </el-col>

    </el-row>
    <el-row>
      <el-col class="left-span" :span="3">
          <span >对账货款：</span>
      </el-col>
      <el-col class="left-span-content" :span="4">
          <span >{{sheet.对账货款}}</span>
      </el-col>
        <el-col class="left-span" :span="3">
          <span >对账费用：</span>
      </el-col>
      <el-col class="left-span-content" :span="4">
          <span >{{sheet.对账费用}}</span>
      </el-col>
        <el-col class="left-span" :span="3">
          <span >账号：</span>
      </el-col>
      <el-col class="left-span-content" :span="4">
          <span >{{sheet.账号}}</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col class="left-span" :span="3">
          <span >对账返利：</span>
      </el-col>
      <el-col class="left-span-content" :span="4">
          <span >{{sheet.对账返利}}</span>
      </el-col>
       <el-col class="left-span" :span="3">
          <span >对账预付：</span>
      </el-col>
      <el-col class="left-span-content" :span="4">
          <span >{{sheet.对账预付}}</span>
      </el-col>
       <el-col class="left-span" :span="3">
          <span >对账应付：</span>
      </el-col>
      <el-col class="left-span-content" :span="4">
          <span >{{sheet.对账应付}}</span>
      </el-col>


    </el-row>
    <el-row>
      <el-col class="left-span" :span="3">
          <span >对账扣补：</span>
      </el-col>
      <el-col class="left-span-content" :span="3">
          <span >{{sheet.对账扣补}}</span>
      </el-col>
        <el-col class="left-span" :span="3">
          <span >对账优惠：</span>
      </el-col>
      <el-col class="left-span-content" :span="3">
          <span >{{sheet.对账优惠}}</span>
      </el-col>
        <el-col class="left-span" :span="3">
          <span >销售成本：</span>
      </el-col>
      <el-col class="left-span-content" :span="3">
          <span >{{sheet.销售成本}}</span>
      </el-col>
    </el-row>
    <el-row>
      <el-col class="left-span" :span="3">
          <span >备注：</span>

      </el-col>
      <el-col class="left-span-content" :span="23-3">
          <span >{{sheet.备注}}</span>
      </el-col>
    </el-row>

    <template>
        <!-- <el-table
              stripe
              border
              show-summary
              style="width: 99%;"
              :max-height="tableMaxHeight"
              :height="tableHeight"
              @cell-click="bccelldblclick"
              fit
             :data="sheet.items" >
                 <el-table-column v-for="(item,key) in sheet.items[0]"
                    show-overflow-tooltip
                  :key="item.prop" :prop="item.prop"  :label="key" >
                     <template slot-scope="scope">
                         <span v-if="key==='单号'"  >
                             <a href="javascript:void(0);" @click="show_detail(scope.row)"> {{scope.row[key]}}</a>
                         </span>
                         <span v-else>
                            {{scope.row[key]}}
                         </span>
                     </template>
                 </el-table-column>
              </el-table> -->

              <vxe-table
                size="mini"
                 ref="xTable"
                 @cell-dblclick="cellDBLClickEvent"
                 height="350"
                 :data.sync="tableData"
                show-footer
                :footer-method="footerMethod"
                :footer-cell-class-name="footerCellClassName"
                border>
          <!-- <vxe-table-column type="index" width="60"></vxe-table-column> -->
          <vxe-table-column v-for="(item,key,index) in tableData[0]" :key="index" :field="key"  :title="key"  > </vxe-table-column>

            </vxe-table>





    </template>




</div>
</template>
<script>
import Form_View from '@/components/Form_View.vue'
import store  from '../store'
import {objKeySort} from '@/components/common/js/printHelp.js'
import { printJson } from '@/components/common/js/printHelp.js'
import  md5  from '../components/common/js/allSchool.js'
import { exportAllDataEvent4 } from '@/components/common/js/util.js'

const printStyle = `
        .title {
          text-align: center;
        }
        .my-list-row {
          display: inline-block;
          width: 100%;
        }
        .my-list-col {
          float: left;
          width: 33.33%;
          height: 28px;
          line-height: 28px;
        }
        .my-top,
        .my-bottom {
          font-size: 12px;
        }
        .my-top {
          margin-bottom: 5px;
        }
        .my-bottom {
          margin-top: 30px;
          text-align: right;
        }
        `;


export default{
    components:{
      exportAllDataEvent4,
          printJson,
      objKeySort
    },
    props:['parameter'],
    data: function() {
        return{
            tableHeight: 0,
            tableMaxHeight:0,
            identifyCode:'',
            identifyCodes:'1234567890',
            sheet:{
                描述:'-',
                供应商编码:'',
                供应商名称:'',
                机构名称:'',
                结算开始日期:'',
                结算方式:'',
                对账货款:'',
                对账返利:'',
                对账扣补:'',
                单号:'',
                操作员:'  ',
                结算结束日期:'',
               结账天数:'',
                对账费用:'',
                对账预付:'',
                对账应付:'',
                对账优惠:'',
                审核状态:'',
                确认状态:'',
                制单日期:'',
                审核日期:'',
                账号:'',
                对账应付:'',
                销售成本:'',
                备注:'',
                items:[]
            },
            tableData:[  ],

        }
    },
     mounted(){

            this.show_detail();
    this.$nextTick(() => {
            console.log(window.innerHeight)
            this.tableHeight = window.innerHeight - 435;
            this.tableMaxHeight=window.innerHeight - 50;

        // 后面的50：根据需求空出的高度，自行调
        })
            },

   methods: {

    exportdata(){
              console.log("测试",this.tableData)
              if(this.tableData.length>0){
                let clo_key=[];
                for (var p1 in this.tableData[0])
                {if(this.tableData[0].hasOwnProperty(p1))
                clo_key.push({title:p1,key:p1})
                }
                  exportAllDataEvent4(this.title,this.$refs.xTable,this.tableData)
                  //export2Excel(clo_key,this.tableData,this.title)
              }
              else{
                confirm("没有数据可以导出");
              }
              },




    printEvent () {
let topHtml = `
      
        <div class="my-top">
          <div class="my-list-row">
            <div class="my-list-col">供应商：`+this.sheet.供应商名称+`</div>
            <div class="my-list-col">订单单号：`+this.sheet.单号+`</div>
            <div class="my-list-col">审核状态：`+this.sheet.审核状态+`</div>
          </div>
          <div class="my-list-row">
            <div class="my-list-col">审核日期：`+this.sheet.审核日期+`</div>
            <div class="my-list-col">机构：`+this.sheet.机构名称+`</div>  
            <div class="my-list-col">备注：`+this.sheet.备注+`</div>         
          </div>
          <div class="my-list-row">
            <div class="my-list-col">对账货款：`+this.sheet.对账货款+`</div>
            <div class="my-list-col">对账应付：`+this.sheet.对账应付+`</div>  
            <div class="my-list-col">批准：`+this.sheet.描述+`</div>         
          </div>
        </div>
        `;
             this.$refs.xTable.print({
                sheetName: '',
                style: printStyle,
                mode:"all",             
                beforePrintMethod: ({content}) => {
                    // 拦截打印之前，返回自定义的 html 内容
                //    return topHtml + content + bottomHtml
                return topHtml + content 
                }
            })

            

      },


       bccelldblclick(row, column, cell, event) {
             console.log(column.label,row['单号'])
             if (column.label==='单号' )
             {this.show_next_sheet(row[column.label])}

           },
           cellDBLClickEvent ({ column,row }) {
              console.log(`单元格双击${column.title}`,row["单号"])
              if (column.title=='对账单号' ||column.title=='单号'   )
              {
                this.show_next_sheet(row[column.title]);
              }
            },

       show_next_sheet(val){
               this.identifyCode="";
               this.makeCode(this.identiyfyCodes,4);
               let trans=val.substring(0,2)
               switch(trans){
                 case 'DP':{
                   store.commit('addtabs',{title:"对账单明细",index:this.identifyCode,content:Statement,parameter:val})
                 break;
                 }
                 case 'PI':{
                    store.commit('addtabs',{title:"采购收货单明细",index:this.identifyCode,content:Form_View,parameter:val})
                    break;
                 }
                 case 'WP':{
                    store.commit('addtabs',{title:"线上采购订单明细",index:this.identifyCode,content:FormEdit,parameter:val})
                    break;
                 }
                 case 'PO':{
                    store.commit('addtabs',{title:"采购订单明细",index:this.identifyCode,content:Form_View,parameter:val})
                    break;
                 }
                 case 'RO':{
                    store.commit('addtabs',{title:"采购退货单明细",index:this.identifyCode,content:Form_View,parameter:val})
                    break;
                 }
                 case 'FG':{
                    store.commit('addtabs',{title:"费用单明细",index:this.identifyCode,content:Form_View,parameter:val})
                    break;
                 }
                  case 'KB':{
                    store.commit('addtabs',{title:"扣补单明细",index:this.identifyCode,content:Form_View,parameter:val})
                    break;
                 }
                 case 'SA':{
                    store.commit('addtabs',{title:"预付单明细",index:this.identifyCode,content:Form_View,parameter:val})
                    break;
                 }
                 case 'CP':{
                    store.commit('addtabs',{title:"购销返利明细",index:this.identifyCode,content:Form_View,parameter:val})
                    break;
                 }
                 case 'LY':{
                    store.commit('addtabs',{title:"联营账款明细",index:this.identifyCode,content:Form_View,parameter:val})
                    break;
                 }
                 case 'DX':{
                    store.commit('addtabs',{title:"代销账款明细",index:this.identifyCode,content:Form_View,parameter:val})
                    break;
                 }
                 case 'KL':{
                    store.commit('addtabs',{title:"扣率代销账款明细",index:this.identifyCode,content:Form_View,parameter:val})
                    break;
                 }
                 case 'ZL':{
                    store.commit('addtabs',{title:"租赁账款明细",index:this.identifyCode,content:Form_View,parameter:val})
                    break;
                 }
               }
            },
            footerCellClassName ({ rowIndex, column, columnIndex }) {
              if (columnIndex === 0) {
                if (rowIndex === 0) {
                  return 'col-blue'
                } else {
                  return 'col-red'
                }
              }
            },
            footerMethod ({ columns, data }) {
              return [
                columns.map((column, columnIndex) => {
                  if (columnIndex === 0) {
                    return '合计'
                  }
                  if (['数量', '箱数','金额','应付金额'].includes(column.property)) {
                    return this.$utils.sum(data, column.property)
                  }
                  return ''
                })
              ]
            },

       submit_sheet(){

           let _this=this
           if(_this.sheet.描述=="")
           {
            _this.sheet.描述="-"
           }
            _this.sheet.单号=this.parameter
             _this.identifyCode="";
             _this.makeCode(_this.identiyfyCodes,4);
            //console.log(_this.sheet.单号)
            let Query={
                supcustNo:this.$store.state.superCode,
                sheetNo:_this.sheet.单号,
                confirmFlag:"1",
                comment:_this.sheet.描述,
                scm_code:this.$store.state.currentMartId,
                ip:this.$store.state.ip,
                unionid:this.$store.state.unionid
            }
            let newkey = objKeySort(Query)
            let par={};
            let md5s='';
             for (let i in newkey)
            {

              if (newkey[i]!='')
              {
                md5s+=i+"="+newkey[i]+"&";
                par[i]=newkey[i];
              }

            }
            if(md5s.length>1){
              md5s=md5s.slice(0,-1)
            }
            console.log("md5s---",md5s);
            let sign= md5.M5(md5s)
               _this.reqM1Service('/SCM/ConfirmDP?sign='+sign,par,'post')
           //_this.$axios.post('/SCM/ConfirmDP?sign='+sign,par)
           .then((res=>{
                 console.log(res)
                if(res.data.code==200){
                    alert("执行成功！")
                    }}))

       },
        show_detail(){

            let _this=this
            _this.sheet.单号=this.parameter
            // _this.identifyCode="";
            // _this.makeCode(_this.identiyfyCodes,4);
            console.log("enter statement")
            let Query={
                supcustNo:this.$store.state.superCode,
                sheetNo:_this.sheet.单号,
                scm_code:this.$store.state.currentMartId,
                ip:this.$store.state.ip,
                unionid:this.$store.state.unionid

            }
            let newkey = objKeySort(Query)
            let par={};
            let md5s='';
             for (let i in newkey)
            {

              if (newkey[i]!='')
              {
                md5s+=i+"="+newkey[i]+"&";
                par[i]=newkey[i];
              }

            }
            if(md5s.length>1){
              md5s=md5s.slice(0,-1)
            }
            let sign= md5.M5(md5s)
            _this.reqM1Service("/SCM/GetDPDetail?sign="+sign,par,'post')
       
             .then((res=>{
                 console.log("GetDPDetail:",res)
                if(res.data.code==200){
                    _this.sheet=res.data.data
                    _this.tableData=res.data.data.items
                    }}))

        },
        radomNum(min,max){
                return Math.floor(Math.random()*(max-min)+min);
            },

        makeCode(o,l){
                for (let i=0 ;i<l;i++){
                    this.identifyCode += this.identifyCodes[
                        this.radomNum(0,this.identifyCodes.length)
                        ];
                }
            },
    },
}

</script>

<style>
.bg-purple-light {
    background: #909399;
  }
  .left-span{

      text-align:right;
      line-height: 26px;
      font-size: 13px;

  }
  .left-span-content{
        line-height: 26px;
        font-size: 13px;
        min-height: 25px;
        border-radius: 0px;
        border-bottom: 1px solid #999;
        }
  .el-row {
    margin-bottom: 3px;
}

.el-col{
    min-height: 25px;
}


</style>