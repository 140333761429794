<template>
<div style="height: 95%;width: 99%;">
<el-row type="flex" class="row-bg" justify="space-between">
    <el-col :span="24">
        <div class="divDzd" >
        <div class="tit">对账单</div>
        <el-table  :data="duizhangdang" border style="width: 100%"
        @cell-click="bccelldblclick">
          <el-table-column v-for="(item,key,index) in duizhangdang[0]"

          :key="index" :prop="item.prop"  :label="key">
          <template slot-scope="scope">
                         <!-- <span v-if="key==='单号'"> -->
                           <span v-if="key==='单号'">
                             <a href="javascript:void(0);"  > {{scope.row[key]}}</a>
                         </span>
                          <span v-else>
                            {{scope.row[key]}}
                         </span>
               </template>
          </el-table-column>
        </el-table>
        </div>
        </el-col>

</el-row>
<el-row>
  <el-col :span="24"><div>
      <div class="tit">近期订单</div>
               <el-table  :data="dindang" border style="width: 100%"
               @cell-click="bccelldblclick" >
          <el-table-column v-for="(item,key,index) in dindang[0]"
          show-overflow-tooltip
          :key="index" :prop="item.prop"  :label="key">
          <template slot-scope="scope">
                         <!-- <span v-if="key==='单号'"> -->
                           <span v-if="key==='单号'">
                             <a href="javascript:void(0);"  > {{scope.row[key]}}</a>
                         </span>
                          <span v-else>
                            {{scope.row[key]}}
                         </span>
               </template>
          </el-table-column>
               </el-table>
      </div></el-col>
</el-row>
</div>
</template>
<script>
import  md5  from '../components/common/js/allSchool.js'
import {objKeySort} from '@/components/common/js/printHelp.js'
import store  from '../store'
import Form_View from '@/components/Form_View.vue'
import Statement from '@/components/Statement.vue'
    export default {
       components:{
      objKeySort
    },
        name: 'simple',
        mounted(){
         this.init('/SCM/HomeGetDP?sign=',"对账单")
          this.init('/SCM/HomeGetMSG?sign=',"待阅")
          this.init('/SCM/HomeGetPO?sign=',"近期订单")
        },
        methods: {
           bccelldblclick(row, column, cell, event) {
             console.log(column.label,row['单号'])
             if (column.label==='单号' )
             {this.show_detail(row[column.label])}

           },
           ///制造随机数
            radomNum(min,max){
                return Math.floor(Math.random()*(max-min)+min);
            },

            makeCode(o,l){
                for (let i=0 ;i<l;i++){
                    this.identifyCode += this.identifyCodes[
                        this.radomNum(0,this.identifyCodes.length)
                        ];
                }
            },
            show_detail(val){

               this.identifyCode="";
               this.makeCode(this.identiyfyCodes,4);
               let trans=val.substring(0,2)

               switch(trans){
                 case 'DP':{
                   store.commit('addtabs',{title:"对账单明细",index:this.identifyCode,content:Statement,parameter:val})
                 break;
                 }
                 case 'PI':{
                    store.commit('addtabs',{title:"采购收货单明细",index:this.identifyCode,content:Form_View,parameter:val})
                    break;
                 }
                 case 'WP':{
                    store.commit('addtabs',{title:"线上采购订单明细",index:this.identifyCode,content:FormEdit,parameter:val})
                    break;
                 }
                 case 'PO':{
                    store.commit('addtabs',{title:"采购订单明细",index:this.identifyCode,content:Form_View,parameter:val})
                    break;
                 }
                 case 'RO':{
                    store.commit('addtabs',{title:"采购退货单明细",index:this.identifyCode,content:Form_View,parameter:val})
                    break;
                 }
               }
            },
          init(url,cs){
            let _this=this
            let Query={
                supcustNo:this.$store.state.userinfo,
                web_id:this.$store.state.userid,
                ip:this.$store.state.ip,
            }
            let newkey = objKeySort(Query)
            let par={};
            let md5s='';
             for (let i in newkey)
            {

              if (newkey[i]!='')
              {
                md5s+=i+"="+newkey[i]+"&";
                par[i]=newkey[i];
              }

            }
            if(md5s.length>1){
              md5s=md5s.slice(0,-1)
            }
            let sign= md5.M5(md5s)
            _this.reqM1Service(url+sign,par,'post')
             //_this.$axios.post(url+sign,par)
             .then((res=>{
                 console.log(res)
                if(res.data.code==200){
                  switch (cs) {
                    case '对账单':{
                      _this.duizhangdang=res.data.data;
                      break;
                    }
                    case '待阅':{
                      _this.daiyue=res.data.data;
                      break;
                    }
                    case '近期订单':{
                      _this.dindang=res.data.data;
                      break;
                    }
                  }

                    }}))


          }
        },

        data: function () {
            return {
              identifyCode:'',
              identifyCodes:'1234567890',

              dindang:[],
              daiyue:[],
              duizhangdang:[]
            }
            }
            }
</script>
<style>
.divDzd {
    padding-right:10px;
    background-color: #f9fafc;
  }
 .el-table th {
  padding: 5px 0 !important;
}
 .el-table td {
  padding: 5px 0 !important;
}
.tit{
  margin: 5px 10px;
}
</style>
