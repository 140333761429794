import store  from '../../../store'
const cIp= function(t){
 
  let url='http://api.thsen.top:15001/getip?cust_name=3333333333333';
  //let par={cust_name:3333333333}
  //t.reqM1Service(url,par,'get')
 t.$axios.get(url)
  .then(res => { 
       t.$store.commit('getlocalIp',res.data.IP)
     //  console.log("获取IP成功",t.$store.state.ip)
      })   
}


function all (e,that){
  let data = {
    multiArray: that.multiArray,
    multiIndex: that.multiIndex
  };
  data.multiIndex[e.detail.column] = e.detail.value;

  // that.setData(data);
  that.multiArray = data.multiArray;
  that.multiIndex = data.multiIndex;
};
////MD5加密
import md5s from './md5.js'
const M5=function (e){
	let a=e+"th22285556"
	return (md5s(a).toUpperCase())

};

const tms=function(){
	var tmp = Date.parse( new Date() ).toString();
	  tmp = tmp.substr(0,10);
	  return (tmp)
};


function radomNum(min,max){
  return Math.floor(Math.random()*(max-min)+min);
}

function   makeCode(l){
  const  identifyCodes='1234567890';
  let result="";
  for (let i=0 ;i<l;i++){
      result += identifyCodes[
          radomNum(0,identifyCodes.length)
          ];
  }
  return (result)

}




////字典排序
function objKeySort(arys) {
  //先用Object内置类的keys方法获取要排序对象的属性名，再利用Array原型上的sort方法对获取的属性名进行排序，newkey是一个数组
  var newkey = Object.keys(arys).sort();　　
  //console.log('newkey='+newkey);
  var newObj = {}; //创建一个新的对象，用于存放排好序的键值对
  for(var i = 0; i < newkey.length; i++) {
      //遍历newkey数组
      newObj[newkey[i]] = arys[newkey[i]];
      //向新创建的对象中按照排好的顺序依次增加键值对

  }
  return newObj; //返回排好序的新对象
};
//将字典内的list转为json,key是字典内list的key
function listTojson(dict,key){
  let new_dict={};
  dict.forEach(element => {

  });

  dict[key]=JSON.stringify(dict[key])

  return dict
}

//阿里云短信业务
const SMSClient = require('@alicloud/sms-sdk')
// ACCESS_KEY_ID/ACCESS_KEY_SECRET 根据实际申请的账号信息进行替换
const accessKeyId = 'LTAIK5GdrIUkcqIc'
const secretAccessKey = '1rOhigN8bzIxgovOHOuP9PgYoQIhQc'

function sms(tel,code){
    let smsClient = new SMSClient({accessKeyId, secretAccessKey})

//发送短信
smsClient.sendSMS({
    PhoneNumbers: tel,//必填:待发送手机号。支持以逗号分隔的形式进行批量调用，批量上限为1000个手机号码,批量调用相对于单条调用及时性稍有延迟,验证码类型的短信推荐使用单条调用的方式；发送国际/港澳台消息时，接收号码格式为：国际区号+号码，如“85200000000”
    SignName: '云通信产品',//必填:短信签名-可在短信控制台中找到
    TemplateCode: 'SMS_1000000',//必填:短信模板-可在短信控制台中找到，发送国际/港澳台消息时，请使用国际/港澳台短信模版
    TemplateParam: '{"code":"'+code+'"}'//可选:模板中的变量替换JSON串,如模板内容为"亲爱的${name},您的验证码为${code}"时。
}).then(function (res) {
    let {Code}=res
    if (Code === 'OK') {
        //处理返回参数
        console.log(res)

    }
}, function (err) {
    console.log(err)
})

}


export default  {
  cIp,
  all: all,
  M5,
  tms,
  objKeySort,
  listTojson,
  makeCode,
  sms
  
}