
//import 'xe-utils'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios'
import echarts from 'echarts'
import VCharts from 'v-charts'
import './components/common/js/dialog'
import {domain} from './components/common/js/util'
//引入 common.js
import common from './components/common/js/common'

Vue.use(common)


import XEUtils from   'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import VXETablePluginExportXLSX from 'vxe-table-plugin-export-xlsx'
import ExportJsonExcel from 'js-export-excel';
import XLSX from 'xlsx';
window.XLSX = XLSX;

import Print from './components/common/js/print'
Vue.use(Print) // 注册


Vue.use(VCharts)
Vue.prototype.$echarts = echarts;
Vue.prototype.$baseConfig=Gold
axios.defaults.timeout = 5000 // 请求超时
//axios.defaults.baseURL = 'http://10.1.3.1:8000/'
//axios.defaults.baseURL = '/api' //apiConfig.baseUrl'';// 配置接口地址

axios.defaults.baseM1URL = domain.Base_M1_URL
//->2,模块二接口,url地址
axios.defaults.baseM2URL = domain.Base_M2_URL



axios.defaults.withCredentials = false;
axios.defaults.timeout=20000;



Vue.prototype.$axios=axios;
Vue.config.productionTip = false
Vue.use(Vuex)
Vue.use(ElementUI)
Vue.use(VXETable)
VXETable.use(VXETablePluginExportXLSX)
//Vue.use(XEUtils)
//Vue.XEUtils = Vue.prototype.$XEUtils = XEUtils;
Vue.prototype.$utils = XEUtils
//Vue.prototype.$cookie = XEUtils.cookie

Vue.prototype.$store = store
router.beforeEach((to,from,next) => {

  if (store.state.unionid || to.path === "/login" || to.path==="/select_mart" || to.path==="/control_login"   || to.path==='/bindopenid' || to.path==='/manual_bind'||to.path==='/weixin_reg') 
  {
    next()
  }  else {
    next({
      path: "/login"
    })
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

