import new_first from '@/components/new_first.vue'
export default  {
    unionid:"",
    ip:"",
    userName:"",
    userinfo :"",
    userid:'',
    currentMart:'',  //当前的超市
    currentMartId:'',
    superCode:'',
    superName:'',
    userstatus:"",//0->普通,1->vip,2,
    userlevel:"",
    bodyWidth:'85%',
    editableTabsValue: '',
    gyllist:[],
    //editableTabsValue: '1-1',
    editableTabs: [
    //     {
    //     title: '首页',
    //     name: '1-1',
    //     content: new_first,
    //     parameter:'',
    //    }
    ],
    tabIndex: 0,
    orderSaveState:true
}
