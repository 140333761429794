export default {
    memberInfo(state) {
        return `会员信息${state.userlevel}`
        /*
        switch (state.userstatus) {

           case 0:
                return '普通会员'
                break
            case 1:
                return 'vip'
                break
            case 2:
                let n = state.userlevel
                return n
                break
            default:
                return '普通会员'
                break
        } */
    }
}
